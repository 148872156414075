<template>
    <div style="margin:10px">
        <Row style="margin-bottom: 20px">

            <Input v-model="newStrategyAddr" placeholder="Enter address" style="width: 400px;margin-right:10px"
                   v-if="isOwner"/>
            <Button type="success" shape="circle" icon="md-add" @click="onClickAdd" v-if="isOwner"> 添加策略</Button>
            <Button style="float:right" shape="circle" icon="md-refresh" @click="onClickRefresh"></Button>

        </Row>
        <Row>
            <Spin size="large" fix v-if="spinShow"></Spin>
            <Card style="width:450px;display: inline-block;margin: 5px" v-for="item in strategyList"
                  :key="item.address">
                <p slot="title">
                    <Icon type="logo-android"/>
                    {{item.name}}
                </p>
                <a href="#" slot="extra" @click.prevent="delIt(item.address)" style="color:red" v-if="isOwner">
                    <Icon type="md-trash"/>
                    删除
                </a>
                <span>
                    {{item.address}}
                    <Button type="text" icon="md-copy" @click="doCopy(item.address)"/>
                </span>
            </Card>


        </Row>

    </div>
</template>

<script>
import TagList from '@/components/common/TagList'
import contract from '@/utils/contracts/common'
import { waitForTx } from '@/utils/tx'
import tools from '@/utils/contracts/tools'

export default {
  name: 'strategy_manager',
  components: {
    TagList,
  },
  data () {
    return {
      newStrategyAddr: '',
      strategyList: [],
      spinShow: false,
      custodyIndex: null,
      isOwner: window.isOwner

    }
  },
  async mounted () {
    console.log('mounted s')
    this.custodyIndex = contract.getCustodyIndex()
    await this.loadStrategies()
  },

  methods: {
    doCopy (txt) {
      // if(txt)
      this.$copyText(txt).then((e) => {
        this.$Message.success('已复制到粘贴板')
        console.log(e)
      }, function (e) {
        this.$Message.error('复制失败')
        console.log(e)
      })
    },
    async loadStrategies () {
      this.spinShow = true
      this.strategyList = await contract.loadStrategies(window.custodyIndexAddress)
      this.spinShow = false
    },
    onClickRefresh () {
      this.loadStrategies()
    },
    onClickAdd () {
      this.newStrategyAddr = tools.checkNoticeAddressValid(this, this.newStrategyAddr)
      if (!this.newStrategyAddr) return

      this.custodyIndex.methods
        .enableStrategy(this.newStrategyAddr)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            this.loadStrategies()
          })
        })
    },
    delIt (strategy) {
      this.custodyIndex.methods
        .disableStrategy(strategy)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            this.loadStrategies()
          })
        })
    }

  }

}
</script>

<style scoped>

</style>
