import { CustodyIndex, Strategy, Vault, ERC20 } from '@/config/abis'
import Store from '@/utils/store'

export default {
  getCustodyIndex (address) {
    address = address ? address : window.custodyIndexAddress
    return new web3.eth.Contract(CustodyIndex, address)
  },
  getVault (address) {
    return new web3.eth.Contract(Vault, address)
  },
  getStrategy (address) {
    return new web3.eth.Contract(Strategy, address)
  },
  getERC20 (address) {
    return new web3.eth.Contract(ERC20, address)
  },
  async getERC20BalanceOf (tokenAddr, address) {
    let erc = this.getERC20(tokenAddr)
    console.log(address)
    return await erc.methods.balanceOf(address).call()
  },
  async getERC20Info (address, method) {
    let erc = this.getERC20(address)
    if (!method) {
      return {
        name: await erc.methods.name().call(),
        symbol: await erc.methods.symbol().call(),
        decimals: await erc.methods.decimals().call(),
      }
    } else {
      await erc.methods[method]().call()
    }

  },
  async loadStrategies (address) {
    address = address ? address : window.custodyIndexAddress
    let custodyIndex = this.getCustodyIndex(address)
    let strategyList = []

    let length = await custodyIndex.methods.strategiesSize().call()
    for (let i = 0; i < length; i++) {
      let stAddr = await custodyIndex.methods.strategies(i).call()
      let isEnable = await custodyIndex.methods.isStrategyEnable(stAddr).call()
      if (isEnable) {
        let st = new web3.eth.Contract(Strategy, stAddr)
        strategyList.push({
          name: await st.methods.strategyName().call(),
          address: stAddr
        })
      }
    }
    console.log()
    let strategies = Store.get('strategies') || {}
    strategies[address] = strategyList
    Store.set('strategies', strategies)
    return strategyList
  },
  loadStrategiesFromCache (address) {
    address = address ? address : window.custodyIndexAddress
    let strategies = Store.get('strategies') || {}
    return strategies[address] || []
  },
  async loadVaults (address) {
    address = address ? address : window.custodyIndexAddress
    let custodyIndex = this.getCustodyIndex(address)
    let vaultList = []

    let length = await custodyIndex.methods.valutsSize().call()
    for (let i = 0; i < length; i++) {
      let vtAddr = await custodyIndex.methods.vaults(i).call()
      let vaultName = await custodyIndex.methods.vaultsName(vtAddr).call()
      // let vault = new web3.eth.Contract(Vault, vtAddr)

      // let strategyAddr = await vault.methods.strategy().call()
      // let strategy = new web3.eth.Contract(Strategy, strategyAddr)
      vaultList.push({
        name: vaultName,
        address: vtAddr,
        harvestType: 1
        // strategyAddr: strategyAddr,
        // strategyName: '',
        // strategyName: await strategy.methods.strategyName().call(),
        // investToken: '',
        // investToken: await strategy.methods.investToken().call(),
      })
    }
    console.log()
    let vaults = Store.get('vaults') || {}
    vaults[address] = vaultList
    Store.set('vaults', vaults)
    return vaultList
  }
}
