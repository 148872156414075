<template>
  <div id="app">
    <Spin fix v-if="loading"></Spin>
    <router-view v-if="!loading" />
  </div>
</template>
<script>
import { chainInfoMap } from "@/utils/tx";
import contract from "@/utils/contracts/common";
import Store from "@/utils/store";
import Web3 from "web3";
import Onboard from "bnc-onboard";

const ethEnabled = () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    window.ethereum.enable();
    return true;
  }
  return false;
};

export default {
  name: "App",
  data() {
    return { loading: false };
  },
  async created() {
    this.$Message.config({
      top: 50,
      duration: 3,
    });
    window._app = this;

    let netId = parseInt(this.$route.query.net || Store.get("netId") || 1);
    console.log("netId", netId);
    // const onboard = Onboard({
    //   dappId: "a6d67945-a99e-4d06-bbf8-1eed0cb76529",
    //   networkId: netId,
    //   walletSelect: {
    //     wallets: [
    //       { walletName: "metamask", preferred: true },
    //       {
    //         walletName: "walletConnect",
    //         rpc: {
    //           [netId]: chainInfoMap[netId].rpcUrls[0],
    //         },
    //       },
    //     ],
    //   },
    //   subscriptions: {
    //     wallet: (wallet) => {
    //       window.web3 = new Web3(wallet.provider);
    //     },
    //   },
    // });
    // await onboard.walletSelect();
    // await onboard.walletCheck();
    // const currentState = onboard.getState();
    // console.log(currentState);

    this.loading = true;

    if (ethEnabled()) {
      this.initAccount();
    } else {
      this.$Message.error({
        background: true,
        content: `需要先安装MetaMask`,
      });
    }
  },
  mounted() {},
  computed: {},
  methods: {
    async initAccount() {
      //init event
      this.bindEvents();

      // init network
      let netId = parseInt(this.$route.query.net);
      let currNetId = parseInt(await web3.eth.net.getId());
      if (netId) {
        let chainInfo = chainInfoMap[netId];
        if (!chainInfo) {
          alert(`${netId} is not found`);
          return;
        }
        if (currNetId !== netId) {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [chainInfo],
            })
            .then((result) => {
              console.log(result);
              window.location.reload();
            })
            .catch((error) => {
              console.log(error);
              this.$Message.error({
                background: true,
                content: `请手动切换 Metamask 网络至${chainInfo.chainName}`,
              });
            });
        }
      }
      window.net = currNetId;

      // init Index
      window.custodyIndexAddress =
        this.$route.query.indexAddr || Store.get("custodyIndexAddress");
      console.log("window.custodyIndexAddress", window.custodyIndexAddress);
      Store.set("custodyIndexAddress", window.custodyIndexAddress);
      if (!window.custodyIndexAddress) {
        this.$Message.error({
          background: true,
          content: `需要先设置Custody Index地址`,
        });
        this.loading = false;
        this.$router.push({ name: "role" });
        return;
      }
      try {
        let custodyIndex = contract.getCustodyIndex();
        window.owner = await custodyIndex.methods.owner().call();
      } catch (e) {
        console.error(e);
        this.$Message.error({
          background: true,
          content: "合约地址错误或处于错误的网络" + e,
        });
        this.$router.push({ name: "role" });
        this.loading = false;
        return;
      }

      if (this.$route.query.indexAddr || this.$route.query.net) {
        this.$router.replace({ query: {} });
      }

      // init Account
      await this.login();
      this.loading = false;
    },
    async login() {
      let accounts = await web3.eth.getAccounts();
      console.log(accounts[0]);
      window.currAddress = accounts[0];
      window.isOwner = window.owner === window.currAddress;
    },
    bindEvents() {
      window.ethereum.on("accountsChanged", (accounts) => {
        window.location.reload();
      });
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    },
  },
};
</script>
<style lang="scss">
html,
body {
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.click-list-row-class {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}
</style>
