import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ViewUI from 'view-design'
// import 'view-design/dist/styles/iview.css';
import './theme/index.less'

Vue.use(ViewUI)
Vue.config.productionTip = true
import VueClipboard from 'vue-clipboard2'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

import LoadScript from 'vue-plugin-load-script';
import Net from '@/utils/net'
Vue.use(LoadScript);
Vue.use(Net);

new Vue({
	el: '#app',
	router: router,
	render: h => h(App)
})
