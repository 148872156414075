<template>

    <div style="position: relative">
        <Spin fix v-if="loading"></Spin>

        <Row class="opRow"> Operator:
            <TagList
                    :itemList="operatorList"
                    :editable="editable"
                    @add="onRoleChange('add','operator',$event)"
                    @delete="onRoleChange('del','operator',$event)"
            />
        </Row>

        <Row class="opRow">
            Harvestor:
            <TagList
                    :itemList="harvestorList"
                    :editable="editable"
                    @add="onRoleChange('add','harvestor',$event)"
                    @delete="onRoleChange('del','harvestor',$event)"
            />
        </Row>

    </div>
</template>

<script>
import TagList from '@/components/common/TagList'
import contract from '@/utils/contracts/common'
import { waitForTx } from '@/utils/tx'
import tools from '@/utils/contracts/tools'

export default {
  name: 'AccessControl',
  components: {
    TagList
  },
  props: {
    vaultAddress: String,
    editable: Boolean
  },
  data () {
    return {
      operatorList: ['0x9620b36841DaCd567032110000a7F090eBf2BCa3', '0xCca471B0d49c0d4835a5172Fd97ddDEA5C979100', '0x6371531A3493466788179AEECe337d38117fa1ac'],
      harvestorList: ['0x9620b36841DaCd567032110000a7F090eBf2BCa3', '0xCca471B0d49c0d4835a5172Fd97ddDEA5C979100', '0x6371531A3493466788179AEECe337d38117fa1ac'],
      loading: false
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      let vault = contract.getVault(this.vaultAddress)
      this.loadEnableRoleList(vault, 'operator')
      this.loadEnableRoleList(vault, 'harvestor')
    },
    async loadEnableRoleList (vault, role) {
      let size = await vault.methods[`${role}Size`]().call()
      console.log(role, 'size', size)
      let roleList = []
      for (let i = 0; i < size; i++) {
        let address = await vault.methods[`${role}List`](i).call()
        console.log(`${role}List`, address)
        let firstUpper = role.replace(/^\S/, s => s.toUpperCase())
        let isEnable = await vault.methods[`is${firstUpper}`](address).call()
        if (isEnable) {
          roleList.push(address)
        }
      }
      this[`${role}List`] = roleList
      this.loading = false
    },
    onRoleChange (action, role, address) {
      console.log(role, address)
      let vault = contract.getVault(this.vaultAddress)
      address = tools.checkNoticeAddressValid(this, address)
      if (!address) {
        this.loadEnableRoleList(vault, role)
        return
      }

      let firstUpper = role.replace(/^\S/, s => s.toUpperCase())
      this.loading = true
      vault.methods[`${action}${firstUpper}`](address)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt, error) => {
            this.loadEnableRoleList(vault, role)
          })
        })
    },

  }
}
</script>

<style scoped>
    .opRow {
        margin-top: 5px;
    }
</style>
