<template>
  <div class="container">
    <div class="row">
      <div class="chain-group">
        <RadioGroup v-model="chainID" type="button">
          <Radio label="ALL" value="ALL"></Radio>
          <Radio label="ETH" value="ETH"> </Radio>
          <Radio label="HECO" value="HECO"></Radio>
          <Radio label="BSC" value="BSC"></Radio>
        </RadioGroup>
        <div class="count-down" v-if="nextRefreshCountDown > 0">
          ({{ nextRefreshCountDown }} 秒后更新)
        </div>
        <div class="refresh-btn" @click="refresh">
          <Icon type="md-refresh" size="20" />
        </div>
      </div>
      <div class="switch-group">
        排序：
        <RadioGroup v-model="orderBy" type="button" button-style="solid">
          <Radio label="APY" value="APY"></Radio>
          <Radio label="TVL" value="TVL"></Radio>
        </RadioGroup>
      </div>
    </div>

    <Table
      className="table-column"
      :columns="columns"
      :data="data"
      :loading="loading"
      border
      disabled-hover
      align="center"
    >
      <template slot-scope="{ row }" slot="project">
        <strong>{{ row.project }}</strong>
      </template>
      <template slot-scope="{ row }" slot="updateTime">
        <div
          class="table-cell"
          :style="!row.isNewUpdateTime ? { color: '#e02f02' } : {}"
        >
          {{ row.updateTime }}
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import moment from "moment";
import { convertToCurrencySeparation } from "@/utils/util";

let timerID = null;
let nextRefreshCountDownTimerID = null;
export default {
  name: "apy-dashboard",
  component: {},
  data() {
    return {
      columns: [],
      data: [],
      loading: false,
      orderBy: "APY",
      chainID: "ALL",
      refreshDuration: 0,
      nextRefreshCountDown: 0,
    };
  },
  mounted() {
    this.getApyList();
  },

  destroyed() {
    this.clearTimer();
  },

  watch: {
    chainID: function (val, oldVal) {
      this.getApyList();
    },
    orderBy: function (val, oldVal) {
      this.getApyList();
    },
  },

  methods: {
    refresh() {
      this.getApyList();
    },

    startTimer() {
      timerID = setTimeout(() => {
        this.getApyList();
      }, this.refreshDuration * 1000);
    },

    clearTimer() {
      if (timerID) {
        clearTimeout(timerID);
      }

      if (nextRefreshCountDownTimerID) {
        clearTimeout(nextRefreshCountDownTimerID);
      }
    },

    countDown() {
      nextRefreshCountDownTimerID = setTimeout(() => {
        this.nextRefreshCountDown -= 1;
        this.countDown();
      }, 1000);
    },

    getApyList() {
      this.loading = true;
      this.columns = [];
      this.data = [];
      this.$net
        .get("get_apys", {
          params: {
            chain_id: this.chainID === "ALL" ? "" : this.chainID,
          },
        })
        .then((data) => {
          const { projectNames, refreshDuration, apyList } = data;

          this.refreshDuration = refreshDuration;
          this.nextRefreshCountDown = refreshDuration;

          this.clearTimer();
          this.startTimer();
          this.countDown();

          const poolTokenList = apyList.map((item) => {
            return item.poolToken;
          });

          const poolTokens = poolTokenList.map((column) => {
            return {
              title: column,
              key: column,
              sortable: true,
              align: "center",
              sortMethod: (a = {}, b = {}, type) => {
                const { apy: aApy = 0, tvl: aTvl = 0 } = a;
                const { apy: bApy = 0, tvl: bTvl = 0 } = b;
                if (this.orderBy === "APY") {
                  return type === "desc"
                    ? aApy < bApy
                      ? 1
                      : -1
                    : aApy > bApy
                    ? 1
                    : -1;
                }
                if (this.orderBy === "TVL") {
                  return type === "desc"
                    ? aTvl < bTvl
                      ? 1
                      : -1
                    : aTvl > bTvl
                    ? 1
                    : -1;
                }
              },
              render: (h, params) => {
                const poolTokenData = params.row[column];
                if (poolTokenData) {
                  const {
                    apy,
                    investApy,
                    rewardApy,
                    tvl,
                    description,
                    isMax,
                    isNewData,
                  } = poolTokenData;
                  return h(
                    "div",
                    {
                      style: {
                        padding: "20px 18px",
                        position: "relative",
                        background: !isNewData
                          ? "rgba(255, 66, 66, 0.2)"
                          : isMax
                          ? "rgba(9, 217, 142, 0.2)"
                          : "inherit",
                      },
                    },
                    [
                      h(
                        "div",
                        {
                          style: {
                            color:
                              !isNewData && this.orderBy === "APY"
                                ? "#e02f02"
                                : isMax && this.orderBy === "APY"
                                ? "#00a44b"
                                : "inherit",
                          },
                          class: this.orderBy === "APY" ? "bold-text-20" : "",
                        },
                        `${apy.toFixed(2)}%`
                      ),
                      h(
                        "div",
                        `${investApy.toFixed(2)}% + ${rewardApy.toFixed(2)}%`
                      ),
                      h(
                        "div",
                        {
                          style: {
                            color:
                              !isNewData && this.orderBy === "TVL"
                                ? "#e02f02"
                                : isMax && this.orderBy === "TVL"
                                ? "#00a44b"
                                : "inherit",
                          },
                          class: this.orderBy === "TVL" ? "bold-text-18" : "",
                        },
                        `TVL：${convertToCurrencySeparation(parseInt(tvl))}`
                      ),
                      description
                        ? h(
                            "Tooltip",
                            {
                              props: {
                                content: description,
                                placement: "top",
                                transfer: true,
                                "max-width": "200",
                              },
                              style: {
                                position: "absolute",
                                right: "18px",
                                top: "20px",
                                zIndex: 99,
                              },
                            },
                            [
                              h("Icon", {
                                props: {
                                  type: "ios-information-circle",
                                  size: 22,
                                },
                              }),
                            ]
                          )
                        : "",
                    ]
                  );
                }
              },
            };
          });

          this.columns = [
            {
              title: " ",
              key: "project",
              width: 100,
              align: "center",
              slot: "project",
            },
            ...poolTokens,
            {
              title: "更新时间",
              key: "updateTime",
              width: 120,
              align: "center",
              slot: "updateTime",
            },
          ];

          const curTimeStamp = Math.floor(Date.now() / 1000);
          const projectData = projectNames.reduce((resultData, projectName) => {
            let minUpdateTime = Infinity;
            const result = apyList.reduce((result, item) => {
              if (item[projectName]) {
                const { investApy, rewardApy } = item[projectName];
                result[item.poolToken] = {
                  ...item[projectName],
                  apy: investApy + rewardApy,
                  isNewData:
                    curTimeStamp - item[projectName].updateTs <=
                    this.refreshDuration,
                };
                if (item[projectName].updateTs < minUpdateTime) {
                  minUpdateTime = item[projectName].updateTs;
                }
              }

              return result;
            }, []);

            if (Object.keys(result).length > 0) {
              resultData.push({
                project: projectName,
                ...result,
                updateTime: moment(minUpdateTime * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                isNewUpdateTime:
                  curTimeStamp - minUpdateTime <= this.refreshDuration,
              });
            }

            return resultData;
          }, []);

          poolTokens.forEach(({ key: poolToken }) => {
            let maxIndex = 0;
            let max = 0;
            projectData.forEach((row, index) => {
              const data = row[poolToken];
              if (data) {
                if (this.orderBy === "APY") {
                  if (data.apy > max) {
                    max = data.apy;
                    maxIndex = index;
                  }
                } else if (this.orderBy === "TVL") {
                  if (data.tvl > max) {
                    max = data.tvl;
                    maxIndex = index;
                  }
                }
              }
            });
            projectData[maxIndex][poolToken].isMax = true;
          });

          this.data = projectData;
        })
        .catch((e) => {
          console.error(e);
          this.$Message.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.ivu-table {
  .ivu-table-cell {
    padding: 0;
  }

  .bold-text-20 {
    font-size: 20px;
    font-weight: bold;
  }

  .bold-text-18 {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>

<style lang="scss" scoped>
.container {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}

.table-cell {
  padding: 0 18px;
}

.chain-group {
  display: flex;
  align-items: center;
}

.switch-group {
  display: flex;
  align-items: center;
}

.count-down {
  margin: 0 0 0 10px;
}

.refresh-btn {
  margin: 0 0 0 20px;
  cursor: pointer;
}
</style>
