const toHex = (num) => {
  return '0x' + num.toString(16)
}
const chainInfoMap = {
  1: {
    chainId: toHex(1),
    chainName: 'ETH Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/0b4a1511b8754bba96dcba520ea63e51'],
    blockExplorerUrls: ['https://etherscan.io']
  },
  3: {
    chainId: toHex(3),
    chainName: 'Ropsten',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://ropsten.infura.io/v3/0b4a1511b8754bba96dcba520ea63e51'],
    blockExplorerUrls: ['https://ropsten.etherscan.io']
  },
  4: {
    chainId: toHex(4),
    chainName: 'Rinkeby',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://rinkeby.infura.io/v3/0b4a1511b8754bba96dcba520ea63e51'],
    blockExplorerUrls: ['https://rinkeby.etherscan.io']
  },
  42: {
    chainId: toHex(42),
    chainName: 'Kovan',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://kovan.infura.io/v3/0b4a1511b8754bba96dcba520ea63e51'],
    blockExplorerUrls: ['https://kovan.etherscan.io']
  },
  56: {
    chainId: toHex(56),
    chainName: 'BSC',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  128: {
    chainId: toHex(128),
    chainName: 'Heco',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18,
    },
    rpcUrls: ['https://http-mainnet.hecochain.com/'],
    blockExplorerUrls: ['https://hecoinfo.com']
  },
  137: {
    chainId: toHex(137),
    chainName: 'Polygon(Matic)',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
    blockExplorerUrls: ['https://polygonscan.com']
  },
  250: {
    chainId: toHex(250),
    chainName: 'Fantom(FTM)',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.ftm.tools'],
    blockExplorerUrls: ['https://ftmscan.com']
  },
}

module.exports = {
  async waitForTx(self, error, transactionHash, callback) {
    console.log(error, transactionHash)
    let netId = await web3.eth.net.getId()
    let linkRender = h => h('a', {
      attrs: {
        target: '_blank',
        href: chainInfoMap[netId].blockExplorerUrls[0] + '/tx/' + transactionHash
      }
    },
      '在区块浏览器上查看 🔗'
    )

    if (error) {
      self.$Notice.error({
        title: '交易发送失败',
        desc: error.message
      })
      if (callback) callback(null, error)
    } else {

      self.$Notice.info({
        title: '正在等待交易上链...',
        duration: 0,
        name: transactionHash,
        render: h => {
          return h('span', [linkRender(h)])
        }
      })

      let handler = window.setInterval(() => {
        web3.eth.getTransactionReceipt(transactionHash, (error, receipt) => {
          console.log(error, receipt)
          if (!error && receipt) {
            self.$Notice.close(transactionHash)
            if (receipt.status) {
              self.$Notice.success({
                title: '交易成功',
                render: h => {
                  return h('span', [linkRender(h)])
                }
              })
            } else {
              self.$Notice.error({
                title: '交易失败',
                render: h => {
                  return h('span', [linkRender(h)])
                }
              })
            }
            window.clearInterval(handler)
            if (callback) callback(receipt, error)
          } else if (error) {
            if (callback) callback(receipt, error)
          }
        })
      }, 3000)

    }
  },
  chainInfoMap: chainInfoMap,
}
