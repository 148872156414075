<template>
    <div>
        <Modal v-model="delModal" width="400">
            <p style="color:#f60;text-align:center">
                <Icon type="ios-information-circle"></Icon>
                <span>确定要删除 {{willDel}} 吗?</span>
            </p>
            <div slot="footer">
                <Button type="error" size="large" long @click="confirm_delete">删除</Button>
            </div>
        </Modal>


        <div v-if="editable">
            <span v-for="item in itemList">
                <Tag type="border" closable color="primary" @on-close="delete_item(item)">{{item}}</Tag>
            </span>
            <EditBox @save="onSave" iconType="md-add" :saveTxt="saveTxt"></EditBox>
        </div>

        <div v-else>
            <span v-for="item in itemList">
                <Tag v-if="item!==''" type="border" color="primary">{{item}}</Tag>
            </span>
        </div>
    </div>


</template>

<script>
// accepts an Array of items and return an Array of items
// edit is a boolean of True and False

import EditBox from '@/components/common/EditBox'

export default {
  name: 'TagList',
  props: {
    'itemList': Array,
    'editable': Boolean,
    saveTxt: {
      type: String,
      default: ''
    },
  },
  components: { EditBox },
  data () {
    return {
      editDict: {},
      willDel: '',
      delModal: false
    }
  },
  methods: {
    delete_item (item) {
      this.willDel = item
      this.delModal = true

    },
    confirm_delete () {
      const index = this.itemList.indexOf(this.willDel)
      this.itemList.splice(index, 1)
      this.$emit('delete', this.willDel)
      this.$emit('update:itemList', this.itemList)

      this.willDel = ''
      this.delModal = false
    },

    add_item (item) {
      if (this.itemList.includes(item)) {
        // window.alert(item + "已经填加了\n" + item + " has already been added!");
      } else {
        this.itemList.push(item)
        this.$emit('add', item)
        this.$emit('update:itemList', this.itemList)
      }
    },
    onSave (txt) {
      if (txt) {
        this.add_item(txt)
      }
    }
  }
}
</script>

<style scoped>

</style>
