<template>
  <div class="home">
    <div class="layout" :class="{ 'layout-hide-text': spanLeft < 3 }">
      <Row type="flex" style="height: 100%">
        <i-col :span="spanLeft" class="layout-menu-left">
          <div class="layout-logo-left">{{ logo_title }}</div>
          <Menu
            :active-name="router_path"
            theme="light"
            width="auto"
            :open-names="['admin', curr_service]"
            @on-select="menuSelect"
          >
            <Menu-item name="/contract-abi">
              <Icon type="ios-list-box" :size="iconSize"></Icon>
              <span class="layout-text">Contract 小工具</span>
            </Menu-item>

            <Menu-item name="/admin/strategy">
              <Icon type="logo-android" :size="iconSize"></Icon>
              <span class="layout-text">Strategy 管理</span>
            </Menu-item>

            <Menu-item name="/admin/vault">
              <Icon type="ios-card" :size="iconSize"></Icon>
              <span class="layout-text">Vault 管理</span>
            </Menu-item>

            <Menu-item name="/admin/role">
              <Icon type="md-planet" :size="iconSize"></Icon>
              <span class="layout-text">Index 管理</span>
            </Menu-item>
          </Menu>
        </i-col>
        <i-col :span="spanRight" style="height: 100%; overflow: scroll">
          <div class="layout-header" style="">
            <i-button type="text" @click="toggleClick">
              <Icon type="md-menu" :size="20"></Icon>
            </i-button>
            <div>{{ menus[curr_subservice] }}</div>
            <div style="padding-right: 50px">
              <!--                            <Avatar style="background-color: #87d068" icon="md-person"/>-->
              <Tag v-if="user.network" type="dot" color="primary">
                {{ chainInfoMap[user.network].chainName }}
              </Tag>
              <span
                style="margin-left: 8px; text-overflow: ellipsis; width: 100px"
                >{{ user.address | omit }}</span
              >
              <Tag
                type="border"
                v-if="user.address"
                :color="isOwner ? 'success' : 'error'"
                style="margin-left: 10px"
              >
                {{ isOwner ? "是 Owner" : "不是 Owner" }}
              </Tag>

              <Button
                v-if="user.address"
                type="text"
                style="margin-left: 20px"
                @click="logout"
                >登出</Button
              >
              <Button
                v-else="user.address"
                type="text"
                style="margin-left: 20px"
                @click="login"
                >连接钱包
              </Button>
            </div>
          </div>
          <div class="layout-content">
            <router-view />
          </div>
          <div class="layout-copy">2021 &copy; DeCustody</div>
        </i-col>
      </Row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { chainInfoMap } from "@/utils/tx";

export default {
  name: "home",
  components: {},
  data() {
    return {
      spanLeft: 3,
      spanRight: 21,
      logo_title: "Sentry Gun",
      router_path: "user_manager",
      menus: {
        strategy: "Strategy 管理",
        vault: "Vault 管理",
        role: "Index 管理",
        "apy-dashboard": "APY 看板",
      },
      chainInfoMap: chainInfoMap,
      user: {
        name: localStorage["username"],
        address: null,
        network: "",
      },
      curr_service: this.$route.path.split("/")[1],
      curr_subservice: this.$route.path.split("/")[2],
      isOwner: window.isOwner,
    };
  },
  computed: {
    iconSize() {
      return this.spanLeft === 4 ? 24 : 24;
    },
  },

  async created() {
    console.log("created home");
    console.log(this.$route.path);
    this.router_path = this.$route.path;
  },
  async mounted() {
    this.login();
  },
  watch: {
    $route(to, from) {
      this.router_path = this.$route.path;
    },
    router_path(to, from) {
      this.curr_service = to.split("/")[1];
      this.curr_subservice = to.split("/")[2];
      // console.log(this.curr_service,this.curr_subservice)
    },
  },
  filters: {
    omit(value) {
      if (value) {
        return (
          value.slice(0, 8) +
          "..." +
          value.slice(value.length - 5, value.length)
        );
      }
      return "";
    },
  },
  methods: {
    toggleClick() {
      //去掉缩起侧边栏
    },
    menuSelect(key) {
      console.log(key);
      this.router_path = key;
      this.$router.push({ path: key }).catch((e) => {
        console.log(e);
      });
    },
    backClick() {
      this.$router.go(-1);
    },

    logout() {
      this.user.address = null;
      this.user.network = null;
    },
    login() {
      this.user.address = window.currAddress;
      this.user.network = window.net;
      // this.isOwner = window.isOwner
    },
  },
};
</script>

<style>
.home {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

.layout {
  /*border: 1px solid #d7dde4;*/
  background: #f5f7f9;
  position: relative;
  /*border-radius: 4px;*/
  overflow: hidden;
  height: 100%;
}

.layout-breadcrumb {
  padding: 10px 15px 0;
}

.layout-content {
  min-height: 100%;
  margin: 10px;
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
}

.layout-content-main {
  padding: 10px;
}

.layout-copy {
  text-align: center;
  padding: 10px 0 20px;
  color: #9ea7b4;
}

.layout-menu-left {
  background: #fff;
  /*height: 100%*/
}

.layout-header {
  height: 64px;
  background: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout-logo-left {
  width: 90%;
  height: 30px;
  background: #5292f2;
  border-radius: 8px;
  margin: 15px auto;
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
  line-height: 30px;
}

.layout-ceiling-main a {
  color: #9ba7b5;
}

.layout-hide-text .layout-text {
  display: none;
}

.ivu-col {
  transition: width 0.2s ease-in-out;
}

.ivu-menu-item {
  display: flex !important;
  align-items: center !important;
}
</style>
