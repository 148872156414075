<template>
  <div class="container">
    <Row>
      <Col><Input v-model="address" placeholder="Enter address" /></Col>
      <Col>
        <Input v-model="abiJson" type="textarea" placeholder="Enter ABI" />
      </Col>
      <!-- <Col> <Button type="primary" shape="circle" @click="go">Go</Button> </Col> -->
    </Row>

    <Row style="width: 100%">
      <Tabs value="name1" style="width: 100%">
        <TabPane label="Read" name="name1">
          <Row>
            <Input v-model="code" placeholder="Enter code" />
          </Row>
          <Row>
            <Button type="primary" shape="circle" @click="run(0)"> Run </Button>
          </Row>
        </TabPane>
        <TabPane label="Write" name="name2">
          <Row>
            <Input v-model="code" placeholder="Enter code" />
          </Row>
          <Row>
            <Button type="primary" shape="circle" @click="run(1)"> Run </Button>
          </Row>
          <Row>
            <InputNumber
              :min="0"
              :step="1"
              v-model="eth_value"
              placeholder="ETH"
            ></InputNumber
          ></Row>
        </TabPane>
      </Tabs>
      <Row style="width: 100%">
        <Input v-model="ans" type="textarea" placeholder="ans"
      /></Row>
    </Row>
  </div>
</template>

<script>
import { waitForTx } from "@/utils/tx";
export default {
  name: "contract-abi",
  component: {},
  data() {
    return {
      address: "",
      abiJson: "",
      abis: [],
      code: "",
      eth_value: 0,
      ans: "",
    };
  },
  mounted() {},

  destroyed() {},

  watch: {},

  methods: {
    async go() {
      let con = new web3.eth.Contract(JSON.parse(this.abiJson), this.address);
      let oracle = await con.methods.oracle().call();
      console.log(oracle);
    },
    async run(type) {
      try {
        let contract = new web3.eth.Contract(
          JSON.parse(this.abiJson),
          this.address
        );
        let tailCode = "";
        let writeCallback = (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (error) {
              this.ans = error;
            } else {
              this.ans = transactionHash;
            }
          });
        };
        if (type === 0) {
          tailCode = ".call()";
        } else {
          tailCode = `.send({ from: window.currAddress, value:${this.eth_value} }, writeCallback)`;
          console.log(tailCode);
        }

        let fun = eval("contract.methods." + this.code + tailCode);
        let ans = await fun;
        if (typeof ans !== "string") {
          this.ans = JSON.stringify(ans);
        } else {
          this.ans = ans;
        }
      } catch (error) {
        this.$Notice.error({
          title: "Error",
          desc: error.message,
        });
        this.ans = error;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
