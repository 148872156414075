<template>
    <div style="margin:10px">

        <Row style="margin-bottom: 20px">

            <Button type="success" shape="circle" icon="md-add" @click="onClickAdd" v-if="isOwner">创建 Vault</Button>
            <Button style="float:right" shape="circle" icon="md-refresh" @click="onClickRefresh"></Button>

        </Row>
        <Row type="flex" justify="start" align="top">
            <Spin size="large" style="height:100%;position:fixed" fix v-if="spinShow"></Spin>
            <Card style="width:48%;display: inline-block;margin: 10px" v-for="(item,index) in vaultList"
                  :key="item.address" v-if="item.isRelated || isOwner">
                <p slot="title">
                    <Icon type="ios-card"/>
                    {{item.name}}
                </p>
                <div slot="extra">
                    <a href="#" @click.prevent="onClickEditName(item)" v-if="isOwner">
                        <Icon type="md-create"/>
                        修改名称
                    </a>
                </div>

                <Collapse simple value="1">
                    <Panel name="1">
                        基本操作
                        <div slot="content">
                            <Row class="opRow">
                                地址： {{item.address}}
                                <Button type="text" icon="md-copy" @click="doCopy(item.address)"/>
                            </Row>

                            <Row class="opRow">
                                策略：
                                <Tag type="border" color="primary">{{item.strategyName}}</Tag>
                                <Button type="text" icon="md-create" @click="onClickEditStrategy(item)"
                                        v-if="item.isOwner"/>
                            </Row>


                            <Row type="flex" class="opRow" v-if="item.isOperator">
                                <Col>
                                    <Input v-model="item.earnValue" placeholder="输入投资数量">
                                        <span slot="append">{{item.investTokenInfo?item.investTokenInfo.symbol:''}}</span>
                                    </Input>
                                </Col>
                                <Col
                                        style="margin-left: 8px;"
                                        v-if="item.investTokenInfo">
                                    <Button @click="onClickMaxEarn(item,index)">Max:
                                        {{divDecimals(item.investTokenBalance,item.investTokenInfo.decimals).toNumber()}}
                                    </Button>
                                </Col>
                                <Col style="margin-left: 8px">
                                    <Button type="warning" @click="onClickEarn(item)">Earn</Button>
                                </Col>

                            </Row>
                            <Row class="opRow" v-if="item.isHarvestor" type="flex">
                                <Select v-model="item.harvestType" style="width:120px">
                                    <Option :value="0">只 Claim</Option>
                                    <Option :value="1">Claim 并卖出</Option>
                                </Select>
                                <Button type="success" style="margin-left: 8px" @click="onClickHarvest(item)">
                                    Harvest
                                </Button>
                            </Row>
                            <Row class="opRow" v-if="item.isHarvestor">
                                <Button type="success" @click="onClickExit(item)">Exit</Button>
                            </Row>
                            <Row class="opRow" v-if="item.isOwner" type="flex">
                                <Col span="8" style="margin-left: 8px">
                                    <Input v-model="item.withdrawValue" placeholder="输入提币数量">
                                    </Input>
                                </Col>

                                <Col span="4">
                                    <Select v-model="item.withdrawToken"
                                            filterable
                                            allow-create
                                            placeholder="选择币种"
                                            @on-create="onCreateToken"
                                            @on-change="onSelectWithdrawToken(item,index)"
                                    >
                                        <Option v-for="token in tokenList"
                                                :value="token.address"
                                                :key="token.address">
                                            {{ token.symbol}}
                                        </Option>
                                    </Select>
                                </Col>

                                <Col style="margin-left: 8px;"
                                     v-if="item.withdrawToken && item.withdrawTokenInfo">
                                    <Button @click="onClickMaxWithdraw(item,index)">
                                        Max:
                                        {{divDecimals(item.withdrawTokenBalance,item.withdrawTokenInfo.decimals).toNumber()}}
                                    </Button>
                                </Col>
                                <Col span="6" style="margin-left: 8px">
                                    <Button type="error" @click="onClickWithdraw(item,index)">
                                        Withdraw
                                    </Button>
                                </Col>

                            </Row>

                        </div>
                    </Panel>
                    <Panel name="2">
                        权限管理
                        <div slot="content">
                            <Row class="opRow">
                                Owner：{{item.owner}}
                                <Button type="text" icon="md-create" @click="onClickEditOwner(item)"
                                        v-if="item.isOwner"/>

                            </Row>

                            <Row class="opRow" v-if="item.pendingOwner!=='0x0000000000000000000000000000000000000000'">
                                待接受 Owner：{{item.pendingOwner}}
                                <Button v-if="myAddr===item.pendingOwner" type="success" style="margin-left: 8px"
                                        @click="onClickAcceptOwner(item.address)">接受成为 Owner
                                </Button>
                            </Row>

                            <AccessControl :editable="item.isOwner" :vaultAddress="item.address"/>

                        </div>
                    </Panel>
                </Collapse>


            </Card>
            <Alert type="warning" show-icon v-if="!haveRole" style="width:80%">
                这里什么都没有
                <template slot="desc">
                    或许是因为你没有任何权限
                </template>
            </Alert>
        </Row>

        <Modal
                v-if=""
                v-model="modalShow"
                :title="modalTitle"
                :loading="modalLoading"
                @on-ok="modalDone">

            <Form ref="modalForm" :model="modalForm" :label-width="70" :rules="ruleValidate">
                <FormItem label="名称" prop="name" v-if="modalType==='create' || modalType==='vaultName'">
                    <Input v-model="modalForm.name" placeholder="输入金库名称"></Input>
                </FormItem>
                <FormItem label="Owner" prop="owner" v-if="modalType==='owner'">
                    <Input v-model="modalForm.owner" placeholder="输入提议的 Owner 地址"></Input>
                </FormItem>

                <FormItem label="策略" prop="strategy" v-if="modalType==='create' || modalType==='strategy'">
                    <Select
                            v-model="modalForm.strategy"
                            filterable
                            :placeholder="selectPlaceholder">
                        <Option v-for="(option, index) in selectList" :value="option.address" :key="index">
                            {{option.name}}
                        </Option>
                    </Select>
                </FormItem>
            </Form>
        </Modal>


    </div>
</template>

<script>
import TagList from '@/components/common/TagList'
import AccessControl from '@/components/role/AccessControl'
import { Strategy, Vault } from '@/config/abis'
import Store from '@/utils/store'
import contract from '@/utils/contracts/common'
import tools from '@/utils/contracts/tools'
import { waitForTx } from '@/utils/tx'

export default {
  name: 'vault_manager',
  components: {
    TagList,
    AccessControl
  },
  data () {
    return {
      newStrategyAddr: '',
      modalShow: false,
      modalTitle: '',
      vaultList: [],
      ruleValidate: {
        name: [
          {
            required: true,
            message: 'The name cannot be empty',
            trigger: 'blur'
          }
        ],
        strategy: [
          {
            required: true,
            message: 'The strategy cannot be empty',
            trigger: 'blur'
          }
        ],
      },
      selectLoading: false,
      select: null,
      selectList: [],
      selectPlaceholder: '',
      modalType: '',
      modalForm: {},
      modalLoading: true,
      custodyIndex: null,
      spinShow: false,
      isOwner: window.isOwner,
      myAddr: window.currAddress,
      tokenList: [],
      haveRole: isOwner
    }
  },
  async mounted () {
    await this.onClickRefresh()
    this.custodyIndex = contract.getCustodyIndex()
  },

  methods: {
    divDecimals: tools.divDecimals,
    mulDecimals: tools.mulDecimals,
    doCopy (txt) {
      // if(txt)
      this.$copyText(txt).then((e) => {
        this.$Message.success('已复制到粘贴板')
        console.log(e)
      }, function (e) {
        this.$Message.error('复制失败')
        console.log(e)
      })
    },
    onClickEditStrategy (item) {
      this.modalTitle = `设置【${item.name}】的策略`
      this.selectPlaceholder = '请选择'
      this.modalType = 'strategy'
      this.selectList = contract.loadStrategiesFromCache()
      this.modalForm = {}
      this.modalForm.strategy = item.strategyAddr
      this.modalForm.vaultAddr = item.address
      this.modalShow = true

      contract.loadStrategies()
    },
    onClickEditName (item) {
      this.modalTitle = `设置【${item.name}】的名称`
      this.modalType = 'vaultName'
      this.modalForm = {}
      this.modalForm.name = item.name
      this.modalForm.vaultAddr = item.address
      this.modalShow = true

    },
    onClickAdd () {
      this.modalTitle = `创建金库`
      this.selectPlaceholder = '请选择'
      this.modalForm = {}
      this.modalType = 'create'
      this.selectList = contract.loadStrategiesFromCache()
      this.modalShow = true

      contract.loadStrategies()
    },
    onClickEditOwner (item) {
      this.modalTitle = `移交【${item.name}】的 Owner`
      this.modalType = 'owner'
      this.modalForm = {}
      this.modalForm.owner = item.owner
      this.modalForm.vaultAddr = item.address
      this.modalShow = true
    },
    onClickEarn (item) {
      console.log(item.earnValue, item.address)
      if (!item.address || !item.earnValue) return
      let vault = contract.getStrategy(item.address)
      let value = this.mulDecimals(item.earnValue, item.investTokenInfo.decimals)
      console.log(value, value.toString())
      vault.methods
        .earn(value.toString())
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.loadVaultSt()
          })
        })
    },
    onClickHarvest (item) {
      let vault = contract.getStrategy(item.address)
      vault.methods
        .harvest(item.harvestType)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.loadVaultSt()
          })
        })
    },
    onClickExit (item) {
      let vault = contract.getStrategy(item.address)
      vault.methods
        .exit()
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.loadVaultSt()
          })
        })
    },
    onClickWithdraw (item, index) {
      if (!item.address || !item.withdrawValue) return
      let vault = contract.getVault(item.address)
      console.log('' + item.withdrawValue)
      let value = this.mulDecimals(item.withdrawValue, item.withdrawTokenInfo.decimals)
      console.log(item.withdrawToken, value.toString())
      vault.methods
        .withdraw(item.withdrawToken, value.toString())
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) {
              this.loadVaultSt()
              this.onSelectWithdrawToken(item, index)
            }
          })
        })
    },
    onClickMaxEarn (item, index) {
      item.earnValue = this.divDecimals(item.investTokenBalance, item.investTokenInfo.decimals).toString()
      console.log(item.earnValue)
      this.$set(this.vaultList, index, item)
    },
    onClickMaxWithdraw (item, index) {
      item.withdrawValue = this.divDecimals(item.withdrawTokenBalance, item.withdrawTokenInfo.decimals).plus(1).toString()
      console.log(item.withdrawValue)
      this.$set(this.vaultList, index, item)
    },
    async onSelectWithdrawToken (item, index) {
      console.log(item.withdrawToken, index)
      let token = await contract.getERC20Info(item.withdrawToken)
      let tokenBalance = await contract.getERC20BalanceOf(item.withdrawToken, item.address)
      item.withdrawTokenInfo = token
      item.withdrawTokenBalance = tokenBalance
      this.$set(this.vaultList, index, item)
    },
    modalDone () {
      this.$refs['modalForm'].validate((valid) => {
        if (valid) {
          console.log(JSON.stringify(this.modalForm))
          if (this.modalType === 'create') {
            this.createVault(this.modalForm.strategy, this.modalForm.name)
          } else if (this.modalType === 'strategy') {
            this.editStrategy(this.modalForm.strategy, this.modalForm.vaultAddr)
          } else if (this.modalType === 'vaultName') {
            this.editVaultName(this.modalForm.name, this.modalForm.vaultAddr)
          } else if (this.modalType === 'owner') {
            this.modalForm.owner = tools.checkNoticeAddressValid(this, this.modalForm.owner)
            if (!this.modalForm.owner) {
              this.modalLoading = false
              return
            }
            this.editVaultOwner(this.modalForm.owner, this.modalForm.vaultAddr)
          }
        } else {
          this.modalLoading = false
        }
      })
    },
    async onClickRefresh () {
      this.spinShow = true
      await contract.loadStrategies()
      this.vaultList = await contract.loadVaults()
      this.loadVaultSt()
      this.loadTokenList()
      this.spinShow = false
    },
    loadVaultSt () {
      this.vaultList.forEach(async (item, index) => {
        let vault = new web3.eth.Contract(Vault, item.address)
        item.owner = await vault.methods.owner().call()

        item.isOwner = item.owner === window.currAddress
        item.pendingOwner = await vault.methods.pendingOwner().call()
        this.$set(this.vaultList, index, item)

        item.isOperator = await vault.methods.isOperator(window.currAddress).call() || item.isOwner
        item.isHarvestor = await vault.methods.isHarvestor(window.currAddress).call() || item.isOperator
        this.$set(this.vaultList, index, item)

        item.isRelated = item.pendingOwner === window.currAddress || item.isHarvestor
        if (!item.isRelated && !this.isOwner) return
        this.haveRole = true

        this.$set(this.vaultList, index, item)

        item.strategyAddr = await vault.methods.strategy().call()

        let strategy = new web3.eth.Contract(Strategy, item.address)
        item.strategyName = await strategy.methods.strategyName().call()
        item.investToken = await strategy.methods.investToken().call()
        // 添加
        this.onCreateToken(item.investToken)

        item.investTokenInfo = await contract.getERC20Info(item.investToken)
        this.$set(this.vaultList, index, item)

        item.investTokenBalance = await contract.getERC20BalanceOf(item.investToken, item.address)
        this.$set(this.vaultList, index, item)

      })
    },
    loadTokenList () {
      let tokenListMap = Store.get('tokenList') || {}
      let tokenList = tokenListMap[window.net] || []
      this.tokenList = tokenList
    },
    async onCreateToken (val) {
      val = tools.checkNoticeAddressValid(this, val)
      console.log(val)
      if (!val) {
        return
      }

      let tokenListMap = Store.get('tokenList') || {}
      let tokenList = tokenListMap[window.net] || []
      for (let token of tokenList) {
        if (token.address === val) {
          // item.withdrawToken = val
          return
        }
      }
      let ercInfo = await contract.getERC20Info(val)
      ercInfo.address = val
      tokenList.push(ercInfo)
      tokenListMap[window.net] = tokenList
      Store.set('tokenList', tokenListMap)
      console.log('tokenListMap', tokenListMap)
      this.loadTokenList()
    },

    createVault (strategyAddr, vaultName) {
      this.custodyIndex.methods
        .createVault(strategyAddr, vaultName)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.onClickRefresh()
            if (!error) {
              this.modalShow = false
            }
          })
        })
    },
    editStrategy (strategyAddr, vaultAddr) {
      let vault = contract.getVault(vaultAddr)
      vault.methods
        .setStrategy(strategyAddr)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.loadVaultSt()
            if (!error) {
              this.modalShow = false
            }
          })
        })
    },
    editVaultName (vaultName, vaultAddr) {
      this.custodyIndex.methods
        .renameVault(vaultAddr, vaultName)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.onClickRefresh()
            if (!error) {
              this.modalShow = false
            }
          })
        })
    },
    editVaultOwner (ownerAddr, vaultAddr) {
      let vault = contract.getVault(vaultAddr)
      vault.methods
        .proposeOwnership(ownerAddr)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.loadVaultSt()
            if (!error) {
              this.modalShow = false
            }
          })
        })
    },
    onClickAcceptOwner (vaultAddr) {
      let vault = contract.getVault(vaultAddr)
      vault.methods
        .acceptOwnership()
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            if (!error) this.loadVaultSt()
          })
        })
    },

    async getTokenInfo (address, method) {
      return await contract.getERC20Info(address, method)
    },
    getStrategyName (stAddr,) {

    }
  }
}
</script>

<style scoped>

    .opRow {
        margin-top: 10px;
    }
</style>
