
export const transformUnderlineObject = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => {
      if (typeof item === "object") {
        return transformUnderlineObject(item);
      }
      return item;
    })
  }

  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = key.replace(/_[a-z]/g, (match) => {
      return match[1].toLocaleUpperCase();
    });
    if (typeof obj[key] === "object") {
      newObj[newKey] = transformUnderlineObject(obj[key]);
    } else {
      newObj[newKey] = obj[key];
    }
    return newObj;
  }, {})
}

export const convertToCurrencySeparation = (num) => {
  num += '';
  if (num.length <= 3) return num;

  num = num.replace(/\d{1,3}(?=(\d{3})+$)/g, (n) => {
    return n + ',';
  });
  return num;
}