import { BigNumber } from 'bignumber.js'
import Web3 from "web3";
BigNumber.config({
  EXPONENTIAL_AT: [-20, 100],
})

export default {
  mulDecimals(num, decimals) {
    let n = new BigNumber(num).times(new BigNumber(10).pow(decimals))
    return n
  },
  divDecimals(num, decimals) {
    return new BigNumber(num).div(new BigNumber(10).pow(decimals))
  },
  checkNoticeAddressValid(self, address) {
    address = address.trim()
    if (!Web3.utils.isAddress(address)) {
      self.$Message.error({
        background: true,
        content: `地址 ${address} 不符合规范`
      })

      return null
    } else {
      return Web3.utils.toChecksumAddress(address)
    }
  }
}
