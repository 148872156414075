<template>
    <span>
<!--        <Icon v-if="!toggler" :type="iconType" size="20" color="#19be6b" @click="clickAdd" style="cursor:pointer"/>-->
        <Button v-if="!toggler" :icon="iconType" type="success" ghost size="small"  @click="clickAdd" >添加</Button>
        <Input v-else v-model="value" :autofocus="true" ref="input"
               @on-enter="submit">
        <Button slot="append" :icon="buttonIco" @click="submit">
        {{saveTxt}}
        </Button>
        </Input>


    </span>
</template>

<script>
export default {
	name: "EditBox",
	props: {
		input: String,
		iconType: {
			type: String,
			default: "md-create"
		},
		saveTxt: {
			type: String,
			default: ""
		},

		buttonIco: {
			type: String,
			default: "md-checkmark"
		}
	},
	mounted() {
		this.value = this.input
	},
	data() {
		return {
			value: "",
			delModal: false,
			toggler: false
		}
	},
	methods: {
		submit() {
			if (this.value) {
				this.$emit("save", this.value)
			}
			this.toggler = false
			this.value = ""
		},
		clickAdd() {
			this.toggler = true
			this.$nextTick(() => {
				console.log(this.$refs)
				this.$refs.input.focus()
			})

		}
	}
}
</script>

<style scoped>

</style>