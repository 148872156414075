<template>
  <div style="margin: 10px">
    <Row style="margin-bottom: 20px">
      <Button
        style="float: right"
        shape="circle"
        icon="md-refresh"
        @click="onClickRefresh"
      ></Button>
    </Row>

    <Row style="margin-bottom: 20px">
      <Form :model="formItem" :label-width="150">
        <FormItem label="当前 Index">
          <Button
            shape="circle"
            icon="md-share"
            style="margin-left: 10px"
            @click="onShareClick"
          >
            Share
          </Button>
        </FormItem>
        <FormItem label="Custody Index 地址">
          <Input
            v-model="formItem.indexAddr"
            placeholder="0x..."
            style="width: 380px"
          >
          </Input>
          <Select
            v-model="formItem.selectNetId"
            style="width: 150px; margin-left: 10px"
          >
            <Option
              v-for="item in chainInfoList"
              :value="item.id"
              :key="item.id"
              >{{ item.chainName }}
            </Option>
          </Select>
          <Button
            type="success"
            icon="md-swap"
            style="margin-left: 10px"
            @click="onClickSwitch"
            >切换</Button
          >
        </FormItem>
        <FormItem label="当前 Owner">
          {{ formItem.owner }}
          <Tag
            size="large"
            type="border"
            :color="formItem.owner === myAddr ? 'success' : 'error'"
            style="margin-left: 10px"
          >
            {{ formItem.owner === myAddr ? "Owner 是我" : "Owner 不是我" }}
          </Tag>
        </FormItem>

        <FormItem label="待接受 Owner">
          {{ formItem.pendingOwner }}
          <Button
            v-if="myAddr === formItem.pendingOwner"
            type="success"
            icon="md-checkmark-circle-outline"
            style="margin-left: 10px"
            @click="onClickAcceptOwner"
          >
            接受成为 Owner
          </Button>
        </FormItem>

        <FormItem label="提议 Owner" v-if="myAddr === formItem.owner">
          <Input
            v-model="formItem.pendingOwner"
            placeholder="0x..."
            style="width: 380px"
          >
          </Input>
          <Button
            type="error"
            icon="md-send"
            style="margin-left: 10px"
            @click="onClickMigrateOwner"
            >移交
          </Button>
        </FormItem>
      </Form>
    </Row>
  </div>
</template>

<script>
import TagList from "@/components/common/TagList";
import contract from "@/utils/contracts/common";
import { waitForTx, chainInfoMap } from "@/utils/tx";
import Store from "@/utils/store";
import tools from "@/utils/contracts/tools";

export default {
  name: "role_manager",
  components: {
    TagList,
  },
  data() {
    let chainInfoList = [];
    for (let id in chainInfoMap) {
      let info = chainInfoMap[id];
      info.id = id;
      chainInfoList.push(info);
    }
    return {
      indexAddr: "",
      formItem: {
        indexAddr: "",
        owner: "",
        pendingOwner: "",
        selectNetId: 1,
      },
      chainInfoList: chainInfoList,
      netId: 1,
      myAddr: "",
    };
  },
  async mounted() {
    // this.netId = await web3.eth.net.getId()
    this.netId = Store.get("netId") || 1;
    this.formItem = {
      indexAddr: window.custodyIndexAddress,
    };
    this.myAddr = window.currAddress;
    this.$set(this.formItem, "selectNetId", "" + this.netId);
    await this.onClickRefresh();
  },

  methods: {
    doCopy(txt) {
      this.$copyText(txt).then(
        (e) => {
          this.$Message.success("已复制到粘贴板");
        },
        function (e) {
          this.$Message.error("复制失败");
          console.log(e);
        }
      );
    },
    async onClickRefresh() {
      try {
        let custodyIndex = contract.getCustodyIndex();

        this.$set(
          this.formItem,
          "owner",
          await custodyIndex.methods.owner().call()
        );
        this.$set(
          this.formItem,
          "pendingOwner",
          await custodyIndex.methods.pendingOwner().call()
        );
      } catch (e) {
        console.error(e);
      }
    },
    async onShareClick() {
      let url = this.genShareUrl(this.netId, window.custodyIndexAddress);
      this.doCopy(url);
    },
    genShareUrl(netId, address) {
      return window.location.origin + `/#/?indexAddr=${address}&net=${netId}`;
    },
    onClickSwitch() {
      if (!this.formItem.selectNetId || !this.formItem.indexAddr) return;
      this.formItem.indexAddr = tools.checkNoticeAddressValid(
        this,
        this.formItem.indexAddr
      );
      if (!this.formItem.indexAddr) return;

      let url = this.genShareUrl(
        this.formItem.selectNetId,
        this.formItem.indexAddr
      );
      Store.set("custodyIndexAddress", this.formItem.indexAddr);
      Store.set("netId", this.formItem.selectNetId);
      this.doCopy(url);
      // window.location.replace(url)
      window.location.reload();
    },
    onClickMigrateOwner() {
      this.formItem.pendingOwner = tools.checkNoticeAddressValid(
        this,
        this.formItem.pendingOwner
      );
      if (!this.formItem.pendingOwner) return;
      let custodyIndex = contract.getCustodyIndex();
      custodyIndex.methods
        .proposeOwnership(this.formItem.pendingOwner)
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            this.onClickRefresh();
          });
        });
    },
    onClickAcceptOwner() {
      let custodyIndex = contract.getCustodyIndex();
      custodyIndex.methods
        .acceptOwnership()
        .send({ from: window.currAddress }, (error, transactionHash) => {
          waitForTx(this, error, transactionHash, (receipt) => {
            this.onClickRefresh();
            window.location.reload();
          });
        });
    },
  },
};
</script>

<style scoped>
</style>
