import Vue from 'vue'
import Router from 'vue-router'
import Home from './pages/Home.vue'

Vue.use(Router)


import StrategyManager from '@/pages/strategy_manager/Index'
import VaultManager from '@/pages/vault_manager/Index'
import RoleManager from '@/pages/role_manager/Index'
import ApyDashboard from '@/pages/apy_dashboard';
import ContractABIPage from '@/pages/contract_abi';


let router = new Router({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			redirect: '/admin/role',
			children: [
				{
					path: 'admin/strategy',
					name: "strategy",
					component: StrategyManager,
				},
				{
					path: 'admin/vault',
					name: "strategy",
					component: VaultManager,
				},
				{
					path: 'admin/role',
					name: "role",
					component: RoleManager,
				},
				{
					path: 'apy-dashboard',
					name: 'apy-dashboard',
					component: ApyDashboard
				},
				{
					path: 'contract-abi',
					name: 'contract-abi',
					component: ContractABIPage
				}
			]
		},
	]
})

router.beforeEach((to, from, next) => {
	console.log(from, to)
	return next()
})

export default router
