import axios from 'axios'
import conf from '@/config/env.js'
import {transformUnderlineObject} from '@/utils/util';

const apiDomain = conf.__API_DOMAIN__


const instance = axios.create({
	baseURL: apiDomain !== '' ? `https://${apiDomain}/v1/apy/` : '/v1/apy/',
	timeout: 60000,
	withCredentials: true,
	headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
	responseType: 'json',
})

instance.interceptors.request.use(config => {
	// config["headers"]["Token"] = localStorage.getItem("token") || ""
	console.log(config)
	return config
})

instance.interceptors.response.use(response => {
	let url = response.request.responseURL
	if (!response.data) {
		_app.$Notice.error({
			title: '接口返回数据格式错误',
			desc: "url:" + url + " | status:" + response.status
		})
		return Promise.reject(response)
	} else {
		if (response.data.success === true) {
			const result = transformUnderlineObject(response.data.result)
			return Promise.resolve(result)
		}
		else if (response.data.code === 1040) {
			localStorage.setItem("token", "")
			_app.$router.push({ name: 'login', query: { "redirect": _app.$router.fullPath } }).catch(e => { console.log(e) })
		}
		else {
			_app.$Notice.error({
				title: "错误",
				desc: response.data.msg
			})
			return Promise.resolve({
				data: response.data,
				flag: false,
				res: response
			})
		}
	}

}, error => {
	if (error.response) {
		if (error.response.status === 400) {
			console.log(error.response)
			let url = error.response.request.responseURL
			_app.$Notice.error({
				title: '请求参数错误',
				desc: "url:" + url + " | " + error.response.data
			})
		} else {
			let url = error.response.request.responseURL
			_app.$Notice.error({
				title: '请求接口异常',
				desc: "url:" + url + " | status:" + error.response.status + " | " + error
			})
		}


	} else {
		_app.$Notice.error({
			title: '网络异常',
			desc: error
		})
	}
	// throw error


	return Promise.reject(error)
	// return Promise.resolve(error.response)
})

const Net = {
	install: function (Vue) {
		Vue.loadScript = Vue.prototype.$net = instance


	},
};


export default Net
