import { render, staticRenderFns } from "./EditBox.vue?vue&type=template&id=4659dc7e&scoped=true&"
import script from "./EditBox.vue?vue&type=script&lang=js&"
export * from "./EditBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4659dc7e",
  null
  
)

export default component.exports