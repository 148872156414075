export default {
  set (key, value) {
    localStorage[key] = JSON.stringify(value)
  },
  get (key) {
    let item = localStorage[key]
    item = item ? JSON.parse(item) : null
    return item
  },
  clear (key) {
    localStorage.removeItem(key)
  },
  clearAll () {
    localStorage.clear()

  }
}
